<template>
  <div class="body">
    <Head></Head>
    <div class="prize-box">
      <div class="prize-title-bg">
        <img src="@/image/achievements/price-1.jpg" alt="" style="width: 80%"/>
      </div>
      <div class="prize-title-bg2">
        <img src="@/image/achievements/price-2.jpg" alt="" style="width: 80%"/>
      </div>
      <div class="prize-news">
        <img src="@/image/achievements/price-3.jpg" alt="" style="width: 80%"/>
      </div>
      <div class="prize-title-bg3">
        <div class="prize-video">
          <video-player
            :video-url="videoPath"
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="false"
            :options="playerOptions"
          >
          </video-player>
        </div>
      </div>

      <div style="padding: 2vh 3vw">
        <swiper :options="swiperOption" ref="mySwiper">
          <!-- slides -->
          <swiper-slide v-for="(item, index) in swiper_list" :key="index">
            <div>
              <img style="width: 80%" :src="item.src" alt="" />
            </div>
          </swiper-slide>

          <!-- Optional controls -->
          <div class="swiper-pagination" slot="pagination"></div>
          <!--                    <div class="swiper-button-prev" slot="button-prev"></div>-->
          <!--                    <div class="swiper-button-next" slot="button-next"></div>-->
          <!--                    <div class="swiper-scrollbar"   slot="scrollbar"></div>-->
        </swiper>
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>

<script>
import Head from "../Head";
import Foot from "../Foot";
// import 'swiper/dist/css/swiper.css'
//
// import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: "prize",
  components: {
    Head,
    Foot,
  },

  data() {
    return {
      swiper_list: [
        { src: require("@/image/achievements/jiangxiang/42.jpg") },
        { src: require("@/image/achievements/jiangxiang/43.jpg") },
        { src: require("@/image/achievements/jiangxiang/44.jpg") },
        { src: require("@/image/achievements/jiangxiang/45.jpg") },
        { src: require("@/image/achievements/jiangxiang/46.jpg") },
        { src: require("@/image/achievements/jiangxiang/47.jpg") },

        { src: require("@/image/achievements/jiangxiang/l13.jpg") },
        { src: require("@/image/achievements/jiangxiang/l10.jpg") },
        { src: require("@/image/achievements/jiangxiang/l11.jpg") },
        { src: require("@/image/achievements/jiangxiang/l12.jpg") },
        { src: require("@/image/achievements/jiangxiang/l1.jpg") },
        { src: require("@/image/achievements/jiangxiang/l2.jpg") },
        { src: require("@/image/achievements/jiangxiang/l3.jpg") },
        { src: require("@/image/achievements/jiangxiang/l4.jpg") },
        { src: require("@/image/achievements/jiangxiang/l5.jpg") },
        { src: require("@/image/achievements/jiangxiang/l6.jpg") },
        { src: require("@/image/achievements/jiangxiang/l7.jpg") },
        { src: require("@/image/achievements/jiangxiang/l8.jpg") },
        { src: require("@/image/achievements/jiangxiang/l9.jpg") },
      ],
      swiperOption: {
        loop: true,
        spaceBetween: 30,
        slidesPerView: 5,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3000,
        },
        // circular:true
      },
      videoPath: "",
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // src: "//path/to/video.mp4", // 路径
            src: "https://vtzn.oss-cn-zhangjiakou.aliyuncs.com/portal/video/chenguo/%E5%A4%84%E9%95%BF.mp4", // 路径
            type: "video/mp4", // 类型
          },
        ],
        // poster: "../../static/images/test.jpg", //你的封面地址
        poster: require("@/image/achievements/jiangxiang/3.1.jpg"), //你的封面地址

        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    // console.log('this is current swiper instance object', this.swiper)
    // var index = 0;
    // this.swiper.slideTo(index, 1000, false)
    // setInterval( () =>{
    //
    //     if(index === 7){
    //         index = 0;
    //     }
    //     this.swiper.slideTo(index, 1000, false)
    //     index++
    // },3000)
  },
  methods: {
    goPrev: function () {
      this.$router.push({ path: "/achievements" });
    },
  },
};
</script>

<style lang="scss" scoped>
.prize-title-bg3 {
  padding: 0 16vw;
  margin-top: -30vh;
}
</style>
